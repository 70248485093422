import React from "react";
import { Helmet } from 'react-helmet';
import "./meetings.scss";


const StaticMeetingsThomPage: React.FC<{ location: Location }> = ({
}) => {
  return (
    <>
    <Helmet>
    <title>Meet with Thom Toogood</title>
  </Helmet>
  
  <div className="banner-logo">
    <img src="https://cdn2.hubspot.net/hubfs/5096486/amazeeio.jpg" />
  </div>

  <iframe
    title={"hubspot"}
    style={{
      width: "100%",
      height: "800px",
    }}
    src={"https://go.amazee.io/meetings/thom-toogood/meeting?embed=true"}
  />
  </>

  );
};
export default StaticMeetingsThomPage;
